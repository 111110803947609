import {
    AppBar,
    IconButton,
    Toolbar,
    Container,
    Button,
    Link,
} from '@mui/material'
import {
    Link as RouterLink,
    useLocation,
    useNavigate,
} from "react-router-dom"
import {
    ArrowBack as BackArrowIcon,
} from '@mui/icons-material'
import LogoIcon from "./icons/logo"
import SearchBar from "./SearchBar"
import Grid from '@mui/material/Unstable_Grid2'

const SiteToolbar = ({
    displaySearchBar,
    displayBackButton,
    closeFilter,
    filteredIngredients,
    updateFilterIngredients,
    updateLemmatizedIngredients,
    color,
}) => {

    const navigate = useNavigate()
    const location = useLocation()

    return (
        <AppBar
            component='nav'
            variant='dense'
            position='sticky'
            sx={{
                backgroundColor: color ? color : 'background.paper',
                borderBottom: "1px solid #ccc"
            }}>
            <Toolbar>
                <Container>
                    <Grid container xs={12} spacing={0}>
                        <Grid container item xs={12}>
                            {displayBackButton &&
                                <Grid item mt={1}>
                                    <IconButton
                                        onClick={() => location.key !== "default" ? navigate(-1)
                                            : navigate("/")}>
                                        <BackArrowIcon />
                                    </IconButton>
                                </Grid>
                            }
                            <Grid item xs={10} sm={4} lg={4}>
                                <Link
                                    component={RouterLink}
                                    to="/">
                                    <LogoIcon />
                                </Link>
                            </Grid>
                            <Grid item xs={2} sm={1.5} lg={1} mt={1.5}>
                                <Button
                                    component={RouterLink}
                                    to="/about"
                                    sx={{
                                        color: 'text.primary',
                                        textDecoration: 'none',
                                        ':hover': {
                                            color: 'text.secondary',
                                        },
                                        align: "left"
                                    }}>About</Button>
                            </Grid>
                            {displaySearchBar &&
                                <Grid item xs={12} md={6.5} mt={1}>
                                    <SearchBar
                                        closeFilter={closeFilter}
                                        filteredIngredients={filteredIngredients}
                                        updateFilterIngredients={updateFilterIngredients}
                                        updateLemmatizedIngredients={updateLemmatizedIngredients} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Container>

            </Toolbar>
        </AppBar >
    )
}

export default SiteToolbar
