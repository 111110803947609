import React, {
  useEffect,
  useState,
} from 'react'
import { useLocation } from "react-router-dom"
import {
  Typography,
  Box,
  Container,
  CircularProgress,
  Link,
  Paper,
  List,
  ListItem,
} from '@mui/material'

import Grid from '@mui/material/Unstable_Grid2'
import axios from 'axios'
import { SiteToolbar, Ingredients } from '../lib/components'
import { cleanUpRecipeName } from '../util'

import "../styles/recipes.scss"

const lemmatize = require('wink-lemmatizer')
const nlpUtils = require('wink-nlp-utils')

const standardize = (word) => {
  word = nlpUtils.string.removePunctuations(word)
  word = word.replace(/ +/g, " ").trim()
  return word
}

const capitalize = (str) => str.replace(/\b\w/g, c => c.toUpperCase())

const removeHtmlTags = (str) => str.replace(/<\/?[^>]+(>|$)/g, "")

function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.random() * Math.random();

  return Math.floor(timestamp * random).toString();
}


export default function Recipe() {

  const recipeId = useLocation().pathname.split("/").pop()

  const [recipe, setRecipe] = useState()
  const [servingSize, setServingSize] = useState()
  const [ingredientList, setIngredientList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [quantityList, setQuantityList] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (!recipe) {

      const query = encodeURIComponent(JSON.stringify({
        "query": {
          "term": {
            "_id": recipeId
          }
        }
      }))

      axios.get(`https://mk5m2tvntd.execute-api.us-west-2.amazonaws.com/internetscookbook-search/opensearch-lambda?q=${query}`)
        .then(response => {
          setRecipe(response.data.hits.hits[0]._source)
        })
    } else {
      let servingSizeText = recipe.recipeYield
      if (isNaN(servingSizeText)) {
        servingSizeText = servingSizeText.replace(" serving(s)", "").replace(" servings.", "")
      }
      setServingSize(servingSizeText)

      const ingredients = recipe.ingredients.map((ingredient, index) => {
        const taggedIngredient = recipe.ingredients_entities[index]
        let name = taggedIngredient['NAME']

        if (name.substring(0, 3) == 'ed ') {
          name = name.substring(3)
        }

        let state = taggedIngredient['STATE']

        const hyphenatedWordMatch = ingredient.match(new RegExp("\\w+-\\w+", "g"))

        const stateMatch = ingredient.match(new RegExp(`\\b\\w*${state}\\w*\\b`))
        if (stateMatch) {
          state = stateMatch[0]
        } else if (hyphenatedWordMatch && hyphenatedWordMatch[0].replace("-", "").startsWith(state)) {
          state = hyphenatedWordMatch[0]
        }

        if (state && name.split(" ").length > 1 && state.endsWith(name.split(" ")[0])) {
          name = name.replace(`${name.split(" ")[0]}`, "")
        }
        return name
      })

      setIngredientList(ingredients.filter(name => name != "").map(name => name.trim()))
      setUnitList(recipe.ingredients.map((_, index) => {
        const taggedIngredient = recipe.ingredients_entities[index]
        const unit = taggedIngredient['UNIT']

        return unit
      }))
      setQuantityList(recipe.ingredients.map((_, index) => {
        const taggedIngredient = recipe.ingredients_entities[index]
        const quantity = taggedIngredient['QUANTITY']

        return quantity 
      }))
    }
  }, [recipe])

  const instructions = () => {
    const ingredientTokens = ingredientList.join(" ").split(" ").map(ingredient => lemmatize.noun(ingredient))

    return (
      <div style={{ paddingTop: "1rem" }}>
        <Typography variant="h3" color="text.primary">
          Instructions
        </Typography>
        <List>
          {recipe.instructions && recipe.instructions.map((instruction, index) => {
            const instructionTokens = removeHtmlTags(instruction).split(/(\s+)/).map(instructionToken => {
              if (instructionToken == " ") {
                return instructionToken
              }
              const baseForm = lemmatize.noun(standardize(instructionToken.toLowerCase()))
              if (baseForm != "" && ingredientTokens.includes(baseForm)) {
                const match = instructionToken.toLowerCase().match(/(\w+)(\W*)/)
                const boldPart = <strong key={generateUniqueId()}style={{ fontSize: "1.1rem", fontFamily: "sans-serif" }}>{capitalize(match[1])}</strong>
                const restPart = match[2]
                return restPart.length > 0 ? [boldPart, restPart] : boldPart
              } else if (baseForm != "" && unitList.includes(baseForm)) {
                const match = instructionToken.toLowerCase().match(/(\w+)(\W*)/)
                const italicPart = <i key={generateUniqueId()} style={{ fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "100" }}>{capitalize(match[1])}</i>
                const restPart = match[2]
                return restPart.length > 0 ? [italicPart, restPart] : italicPart
              } else if (baseForm != "" && quantityList.includes(baseForm)) {
                const match = instructionToken.toLowerCase().match(/(\w+)(\W*)/)
                const italicPart = <strong key={generateUniqueId()} style={{ fontSize: "1.1rem", fontFamily: "sans-serif", fontWeight: "500" }}>{match[1]}</strong>
                const restPart = match[2]
                return restPart.length > 0 ? [italicPart, restPart] : italicPart
              } else {
                return instructionToken
              }
            })

            return (
              <ListItem key={generateUniqueId()}>
                <Paper sx={{ padding: "1rem" }}>
                  {instructionTokens}
                </Paper>
              </ListItem>
            )
          })}
        </List>
      </div>
    )
  }

  if (!recipe) {
    return <CircularProgress color="primary" />
  }

  return (
    <>
      <SiteToolbar
        displayBackButton
        color="secondary.main" />
      <Container maxWidth="md" fixed
        sx={{
          paddingTop: "2rem",
        }}>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          <Paper sx={{ padding: "1rem" }}>
            <Grid item container>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={recipe.imageUrl}
                  sx={{
                    maxWidth: "300px",
                    maxHeight: "100%",
                    borderRadius: 4,
                    overflow: "hidden",
                    filter: "brightness(100%) contrast(110%) hue-rotate(350deg)",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" color="text.primary">{cleanUpRecipeName(recipe.name)}</Typography>
                <Typography fontSize="1.25rem" variant="subtitle1" color="text.secondary">Serving size: {servingSize}</Typography>
                <Typography variant="subtitle2">
                  <Link
                    color="info.dark"
                    href={recipe.link}>
                    {recipe.link}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid xs={12} md={6}>
              <Ingredients recipe={recipe} />
            </Grid>
            <Grid xs={12} md={6}>
              {instructions()}
            </Grid>
          </Grid>
        </Grid>
      </Container >
    </>
  )
}
