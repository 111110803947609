import React, {
    useState,
    useEffect,
} from 'react'
import { useLocation } from "react-router-dom"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import SiteToolbar from "../components/SiteToolbar"
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { makeStyles } from "@mui/styles"
import {
    ExpandMore as ExpandMoreIcon,
    ArrowForwardSharp as ArrowForwardSharpIcon
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import PrivacyPolicy from '../components/PrivacyPolicy'


const useStyles = makeStyles((theme) => ({
    h1: {
        color: theme.palette.text.secondary
    },
    p: {
        color: theme.palette.text.primary
    }
}))

const AccordionStyled = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))

const AccordionSummaryStyled = styled((props) => (
    <AccordionSummary
        expandIcon={<ArrowForwardSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const TimelineContentFuturistic = styled(TimelineContent)(({ theme }) => ({
    textShadow: '0px 0px 10px #fff, 0px 0px 20px #fff, 0px 0px 30px #fff, 0px 0px 40px #ff00de',
}))

const TimelineItemFuturisticTop = styled(TimelineItem)(({ theme }) => ({
    background: 'linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4)',
    color: 'white',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
}))

const TimelineItemFuturisticBottom = styled(TimelineItem)(({ theme }) => ({
    background: 'linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4)',
    color: 'white',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px'
}))

const TimelineDotFuturistic = styled(TimelineDot)(({ theme }) => ({
    background: '#3f3d56',
    border: '2px solid #3f3d56',
    boxShadow: '0 0 0 2px #3f3d56',
}))

const About = () => {

    const [expanded, setExpanded] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (location.hash === "#privacy-policy") {
            setExpanded(true);
        }
    }, [])

    const classes = useStyles()
    return (
        <div>
            <SiteToolbar />
            <Container maxWidth="xl"
                sx={{
                    padding: "1rem",
                    backgroundColor: "background.paper"
                }}>
                <Typography variant="h1"
                    sx={{
                        backgroundColor: "secondary.main",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        padding: "1rem"
                    }}>
                    Explore Internet's Cookbook
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AccordionStyled>
                            <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                                <h3>Our Mission</h3>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <p>Our mission is to provide a digital cookbook that the internet deserves, one that makes finding and cooking recipes easy and stress-free.</p>
                                <p>No blogs.</p>
                            </AccordionDetailsStyled>
                        </AccordionStyled>
                        <AccordionStyled>
                            <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                                <h3>How it works</h3>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <p>The Internet's Cookbook uses advanced web scraping and natural language processing techniques to index and extract information from recipe websites across the internet. Our search engine then uses this information to make it easy for you to find the perfect recipe for any occasion by searching by both name and ingredient.</p>
                            </AccordionDetailsStyled>
                        </AccordionStyled>
                        <AccordionStyled>
                            <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                                <h3>Upcoming Features</h3>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <Timeline position="alternate">
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <h4>Specify desired serving size</h4>
                                            <p>Specify how many servings you want to make and the ingredients will adjust accordingly.</p>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <h4>Different measurement systems</h4>
                                            <p>Choose between different measurement systems (cups, grams, ounces) to easily understand recipe instructions.</p>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItemFuturisticTop>
                                        <TimelineSeparator>
                                            <TimelineDotFuturistic color="primary" />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <h4>Create meal plans</h4>
                                            <p>Create meal plans by selecting multiple recipes and organizing them into a plan for the week.</p>
                                        </TimelineContent>
                                    </TimelineItemFuturisticTop>
                                    <TimelineConnector />
                                    <TimelineItemFuturisticBottom>
                                        <TimelineSeparator>
                                            <TimelineDotFuturistic color="primary" />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <h4>Easily order groceries</h4>
                                            <p>Easily order groceries based on your meal plans for even easier meal preparation.</p>
                                        </TimelineContent>
                                    </TimelineItemFuturisticBottom>
                                </Timeline>
                            </AccordionDetailsStyled>
                        </AccordionStyled>
                        <AccordionStyled expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
                            <AccordionSummaryStyled id="privacy-policy" expandIcon={<ExpandMoreIcon />}>
                                <h3>Privacy Policy</h3>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <PrivacyPolicy />
                            </AccordionDetailsStyled>
                        </AccordionStyled>
                    </Grid>
                </Grid>
                <p className={classes.p}>We hope you enjoy using Internet's Cookbook and happy cooking!</p>
            </Container>
        </div>)
}

export default About
