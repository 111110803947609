import React from "react"
import {
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
} from "@mui/material"
import { IngredientLine } from "../lib/components"

const Ingredients = ({ recipe }) => (
    <div style={{ paddingTop: "1rem" }}>
        <Typography variant="h3" color="text.primary">
            Ingredients
        </Typography>
        <Paper>
            <List dense={true}>
                {recipe.ingredients && recipe.ingredients.map((ingredient, index) => {
                    const taggedIngredient = recipe.ingredients_entities[index]
                    const isOptional = new RegExp(`\\boptional\\b`, "i").test(ingredient)

                    return (
                        <ListItem key={index}>
                            <ListItemText sx={{ display: 'flex' }}>
                                <Typography>
                                    {isOptional && <i style={{
                                        fontSize: "1.1rem",
                                        fontWeight: "bolder"
                                    }}>Optional</i>} <IngredientLine ingredient={ingredient} taggedIngredient={taggedIngredient} />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )
                })}
            </List>
        </Paper >
    </div>
)

export default Ingredients