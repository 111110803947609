import React from "react"
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import "./index.css"
import App from "./App"
import Recipe from "./routes/recipe"
import About from "./routes/about"
import ErrorPage from "./error-page"
import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from '@mui/material/CssBaseline'
import theme from "./styles/theme"
import '@fontsource/roboto'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:mealType",
    element: <App />,
    errorElement: <ErrorPage />
  }
  ,
  {
    path: "recipes/:recipeName/:recipeId",
    element: <Recipe />
  },
  {
    path: "about/",
    element: <About />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <div style={{
        background: theme.palette.background.default,
      }}>
        <RouterProvider router={router} />
      </div >
    </ThemeProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
