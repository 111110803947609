import {
  React,
  useState,
  useEffect,
  useCallback,
} from "react"
import {
  ReactiveBase,
  ReactiveList,
  SingleList,
} from "@appbaseio/reactivesearch"
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Container,
  Box,
  Fade,
  useScrollTrigger,
  Fab,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material'
import Grid from '@mui/material/Unstable_Grid2'
import {
  SiteToolbar,
  RecipeCard,
} from "./lib/components"
import Masonry from '@mui/lab/Masonry'
import "animate.css/animate.min.css"
import "./styles/SearchResults.scss"
import { useWindowPosition } from "./hooks"

const lemmatize = require('wink-lemmatizer')

function ScrollTop(props) {
  const { children, window } = props

  const trigger = useScrollTrigger({
    threshold: 400,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    )

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      })
    }
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 30, right: 40, zIndex: 1000 }}
      >
        {children}
      </Box>
    </Fade>
  )
}

function App() {

  const [filteredIngredients, setFilteredIngredients] = useState(JSON.parse(sessionStorage.getItem("filteredIngredients")) || [])
  const [query, setQuery] = useState({ "match_all": {} })
  const [lemmatizedIngredients, setLemmatizedIngredients] = useState([])
  const [infiniteScrollMode, setInifiniteScrollMode] = useState(JSON.parse(sessionStorage.getItem("infiniteScroll")) || false)

  const location = useLocation()
  const navigate = useNavigate()

  const [mealType, setMealType] = useState(location.pathname.substring(1))

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.only("xs"))
  const sm = useMediaQuery(theme.breakpoints.up("sm"))
  const md = useMediaQuery(theme.breakpoints.only("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  const updateLemmatizedIngredients = useCallback((newLemmatizedIngredients) => {
    setLemmatizedIngredients(newLemmatizedIngredients)
  }, [])

  useEffect(() => {
    sessionStorage.setItem("infiniteScroll", JSON.stringify(infiniteScrollMode))
  }, [infiniteScrollMode])

  useEffect(() => {
    if (!["breakfast", "brunch", "lunch", "dinner", "snacks", "snack", "dessert"].includes(mealType)) {
      setMealType(null)
      navigate("/")
    }
    if (mealType === "") {
      setMealType(null)
    } else if (mealType === "snacks") {
      setMealType("snack")
    }
    if (mealType) {
      navigate(mealType != "snack" ? `/${mealType}` : "/snacks")
    }
  }, [mealType])

  useEffect(() => {

    sessionStorage.setItem("filteredIngredients", JSON.stringify(filteredIngredients))
    if (filteredIngredients.length === 0) {
      setQuery({ "match_all": {} })
    } else {
      const visitedIngredients = []

      const newQuery = {
        "bool": {
          "must": []
        }
      }

      filteredIngredients.forEach(ingredient => {
        const lemmatizedIngredient = lemmatize.noun(ingredient)

        if (!visitedIngredients.includes(ingredient)) {
          if (!lemmatizedIngredients.includes(ingredient)) {
            newQuery.bool.must.push({
              "bool": {
                "should": [
                  { "wildcard": { "ingredients_entities.NAME.keyword": `${ingredient}*` } },
                  { "wildcard": { "ingredients_entities.NAME.keyword": `* ${ingredient} *` } },
                  { "wildcard": { "ingredients_entities.NAME.keyword": `* ${ingredient}.` } },
                  { "wildcard": { "ingredients_entities.NAME.keyword": `${lemmatizedIngredient}*` } },
                  { "wildcard": { "iingredients_entities.NAME.keyword": `* ${lemmatizedIngredient} *` } },
                  { "wildcard": { "ingredients_entities.NAME.keyword": `* ${lemmatizedIngredient}.` } }
                ]
              }
            })
            visitedIngredients.push(ingredient, lemmatizedIngredient)
          }
        }
      })

      filteredIngredients.filter(ingredient => !visitedIngredients.includes(ingredient))
        .forEach(ingredient => newQuery.bool.must.push({ "match": { "ingredients_entities.NAME.keyword": ingredient } }))

      setQuery(newQuery)
    }
  }, [lemmatizedIngredients, filteredIngredients])

  const masonryDefaultHeight = useCallback(() => {
    if (xs) {
      return 22000
    }
    if (sm) {
      return 10000
    }
    if (md) {
      return 8000
    }
    if (lg) {
      return 6000
    }
  }, [xs, sm, md, lg])

  const masonryDefaultColumns = useCallback(() => {
    if (xs) {
      return 1
    }
    if (sm) {
      return 2
    }
    if (md) {
      return 3
    }
    if (lg) {
      return 4
    }
  }, [xs, sm, md, lg])

  const closeFilter = useCallback((value) => {
    const newFilters = filteredIngredients
      .filter(ingredient => ingredient !== value)
      .filter(ingredient => lemmatize.noun(ingredient) !== value)
    setFilteredIngredients(newFilters)
  }, [filteredIngredients])

  const updateFilterIngredients = useCallback((newFilteredIngredients) => {
    setFilteredIngredients(newFilteredIngredients)
  }, [])

  const infiniteScrollSwitch = () => (
    <FormGroup sx={{ marginLeft: "0.5rem", marginTop: "-0.5rem" }}>
      <FormControlLabel control={
        <Switch checked={infiniteScrollMode} onChange={() => setInifiniteScrollMode(!infiniteScrollMode)} />
      }
        label={<Typography fontSize="1rem" variant="subtitle1">Infinite scroll</Typography>} />
    </FormGroup>
  )

  const handleMealTypeChange = useCallback((event, newMealType) => {
    setMealType(newMealType)
  }, [])

  const noTopBorder = useCallback(() => {
    return { borderTop: '0px' }
  }, [])

  const mealTypeFilters = useCallback(() => {
    return (
      <div>
        <div style={{ display: "none" }}>
          <SingleList
            componentId="MealTypeFilter"
            dataField="meal_type.keyword"
            showSearch={false}
            showRadio={false}
            showCount={false}
            size={6}
            value={mealType}
          />
        </div>

        <ToggleButtonGroup
          color="primary"
          fullWidth={true}
          value={mealType}
          exclusive
          onChange={handleMealTypeChange}
          aria-label="Meal type"
        >
          <ToggleButton sx={noTopBorder()} value="breakfast">Breakfast</ToggleButton>
          <ToggleButton sx={noTopBorder()} value="brunch">Brunch</ToggleButton>
          <ToggleButton sx={noTopBorder()} value="lunch">Lunch</ToggleButton>
          <ToggleButton sx={noTopBorder()} value="dinner">Dinner</ToggleButton>
          <ToggleButton sx={noTopBorder()} value="dessert">Dessert</ToggleButton>
          <ToggleButton sx={noTopBorder()} value="snack">Snacks</ToggleButton>
        </ToggleButtonGroup>
      </div>
    )
  }, [mealType, handleMealTypeChange, noTopBorder])

  return (
    <ReactiveBase
      url="https://mk5m2tvntd.execute-api.us-west-2.amazonaws.com/internetscookbook-search/opensearch-lambda"
      app="_" // ReactiveBase throws an error if app is left blank. We don't need it because we transform the request for AWS api gateway
      transformRequest={(request) => {
        // removes appbase specific first line from request body
        const query = encodeURIComponent(request.body.substring(request.body.indexOf("\n") + 1))
        request.url = `${request.url.replace("/_/_msearch", "")}q=${query}`
        request.method = "GET"
        delete request.headers
        delete request.body
        return request
      }}
      theme={{
        typography: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize + 'px',
        },
        colors: {
          backgroundColor: theme.palette.background.default,
          textColor: theme.palette.text.secondary,
          titleColor: theme.palette.text.primary,
          primaryColor: theme.palette.primary.main,
          secondaryColor: theme.palette.secondary.main,
          alertColor: theme.palette.info.main,
          borderColor: theme.palette.primary.main,
        },
      }}
    >
      <SiteToolbar
        displaySearchBar={true}
        updateLemmatizedIngredients={updateLemmatizedIngredients}
        closeFilter={closeFilter}
        filteredIngredients={filteredIngredients}
        updateFilterIngredients={updateFilterIngredients}
        color="background.paper" />
      <ScrollTop>
        <Fab size="large" color="secondary" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Container
        sx={{
          overflow: { xs: "scroll", sm: "auto" },
        }}
      >
        {mealTypeFilters()}
      </Container>
      <Container maxWidth="lg"
        sx={{ overflow: "hidden" }}>
        <a id="back-to-top-anchor"></a>
        <Grid
          container
          spacing={2}
          mt={2}
          justifyContent="center"
          alignContent="center"
          alignItems="center">
          <Grid item xs={12}>
            <ReactiveList
              key={infiniteScrollMode.toString()}
              componentId="resultList"
              dataField="_score"
              includeFields={["name", "author", "imageUrl", "rating", "ratingCount"]}
              infiniteScroll={infiniteScrollMode}
              size={8}
              pagination={!infiniteScrollMode}
              sortOptions={[
                { label: 'Most Popular', dataField: 'ratingCount', sortBy: 'desc' },
                { label: 'Most Relevant', dataField: '_score', sortBy: 'desc' },
              ]}
              defaultQuery={() => ({
                track_total_hits: true,
                query: query,
              })}
              react={{
                and: ["search", "MealTypeFilter"]
              }}
              renderResultStats={(stats) => (
                <div style={{ display: 'flex', flexDirection: sm ? 'row' : 'column' }}>
                  <Typography variant="subtitle2" fontSize="0.9rem">
                    {`${stats.numberOfResults} results found in ${stats.time}ms`}
                  </Typography>
                  {infiniteScrollSwitch()}
                </div>
              )}
              className="search-results"
              innerClass={{
                sortOptions: 'sort-options',
                resultsInfo: 'results-info',
              }}
              loader={
                <div style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  background: "background.default"
                }}>
                  <CircularProgress color="primary" />
                </div>
              }
              renderError={(error) => (
                <div>
                  Something went wrong!<br />Error details<br />{error}
                </div>
              )}
            >
              {({ loading, data }) => {
                return (
                  <Container maxWidth="xl">
                    <Masonry
                      columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                      defaultHeight={masonryDefaultHeight()}
                      defaultColumns={masonryDefaultColumns()}
                      defaultSpacing={1.5}
                      sx={{
                        overflow: "visible",
                        alignContent: "center"
                      }}
                      spacing={1.5}>
                      {data.map((item, index) => {
                        return <RecipeCard
                          key={item._id}
                          recipe={item} />
                      })}
                    </Masonry>
                  </Container>
                )
              }}
            </ReactiveList>
          </Grid>
        </Grid>
      </Container>
    </ReactiveBase >
  )
}

export default App