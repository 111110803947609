import { IconButton, Box } from "@mui/material"
import { makeStyles } from "@mui/styles"


const useStyles = makeStyles((theme) => ({
    svg: {
        //fill: theme.palette.secondary.main,
        border: "1px solid black",
        borderRadius: "5px"
    }
}))

const LogoIcon = () => {
    const classes = useStyles()
    return (
        <IconButton disabled={true}>
            <Box component="img" width="100%" height="2.5rem" src="/title2.svg" />
        </IconButton>
    )
}

export default LogoIcon
