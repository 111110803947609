import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
} from 'react'
import {
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Typography,
    Chip,
} from '@mui/material'
import {
    Close as CloseIcon,
} from '@mui/icons-material'
import Grid from '@mui/material/Unstable_Grid2'
import {
    Search as SearchIcon,
} from '@mui/icons-material'
import {
    DataSearch,
} from "@appbaseio/reactivesearch"
import axios from 'axios'
import "../styles/SearchBar.scss"
import Masonry from '@mui/lab/Masonry'

const lemmatize = require('wink-lemmatizer')

const SearchBar = ({
    closeFilter,
    updateLemmatizedIngredients,
    filteredIngredients,
    updateFilterIngredients,
}) => {
    const [ingredientSuggestions, setIngredientSuggestions] = useState([])
    const [lemmatizedIngredientMap, setLemmatizedIngredientMap] = useState({})
    const [searchObject, setSearchObject] = useState({ value: '' })
    const [isOpen, setIsOpen] = useState(false)

    const dataSearchRef = useRef()

    useEffect(() => {
        let handleBodyScroll
        if (!('ontouchstart' in window)) {
            handleBodyScroll = (event) => {
                if (dataSearchRef.current && dataSearchRef.current.state.isOpen) {
                    event.preventDefault()
                }
            }
            document.body.addEventListener('touchmove', handleBodyScroll, { passive: false })
        }

        return () => {
            if (handleBodyScroll) {
                document.body.removeEventListener('touchmove', handleBodyScroll)
            }
        }
    }, [])

    useEffect(() => {
        if ('triggerQuery' in searchObject) {
            searchObject.triggerQuery()
        }
    }, [searchObject])

    const cachedIngredients = useMemo(async () => {
        const response = await axios.get(`https://mk5m2tvntd.execute-api.us-west-2.amazonaws.com/internetscookbook-search/opensearch-lambda?q=${encodeURIComponent(JSON.stringify({
            "size": 0,
            "aggs": {
                "ingredient_pivot": {
                    "terms": {
                        "field": "ingredients_entities.NAME.keyword",
                        "size": 2000
                    },
                    "aggs": {
                        "count": {
                            "value_count": {
                                "field": "@timestamp"
                            }
                        },
                        "count_filter": {
                            "filter": {
                                "range": {
                                    "count": {
                                        "gte": 200
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }))}`)

        const ingredients = response.data.aggregations.ingredient_pivot.buckets
        const ingredientMap = {}

        const lemmatizedIngredients = ingredients.map((ingredient) => {
            const lemmatizedIngredient = lemmatize.noun(ingredient.key)
            if (lemmatizedIngredient !== ingredient.key) {
                ingredientMap[lemmatizedIngredient] = ingredient.key
            }
            return lemmatizedIngredient
        })

        const foundIngredients = [...new Set(lemmatizedIngredients)]

        return {
            ingredientSuggestions: foundIngredients,
            lemmatizedIngredientMap: ingredientMap,
        }
    }, [])

    useEffect(() => {
        cachedIngredients.then((response) => {
            setIngredientSuggestions(response.ingredientSuggestions)
            updateLemmatizedIngredients(response.ingredientSuggestions)
            setLemmatizedIngredientMap(response.lemmatizedIngredientMap)
        })
    }, [cachedIngredients, updateLemmatizedIngredients]) 

    const clearAllFilters = () => updateFilterIngredients([])

    const ingredientSuggestion = useMemo(() => {
        const lemmatizedIngredientMap = {}

        return (ingredientItem, index) => {
            let newFilteredIngredients = ingredientItem
            const unlemmatizedIngredient = lemmatizedIngredientMap[ingredientItem]
            if (unlemmatizedIngredient) {
                newFilteredIngredients = [ingredientItem, unlemmatizedIngredient]
            }

            return (
                <ListItem
                    key={index}
                    onClick={() => {
                        if (newFilteredIngredients instanceof Array) {
                            updateFilterIngredients([...filteredIngredients, ...newFilteredIngredients])
                        } else {
                            updateFilterIngredients([...filteredIngredients, newFilteredIngredients])
                        }
                    }}
                >
                    <ListItemText primary={ingredientItem} />
                    <Checkbox
                        checked={
                            filteredIngredients.includes(ingredientItem) ||
                            filteredIngredients.includes(unlemmatizedIngredient)
                        }
                        color="primary"
                        onChange={() => {
                            if (newFilteredIngredients instanceof Array) {
                                if (
                                    filteredIngredients.includes(ingredientItem) ||
                                    filteredIngredients.includes(unlemmatizedIngredient)
                                ) {
                                    updateFilterIngredients(
                                        filteredIngredients.filter(
                                            (ingredient) => !newFilteredIngredients.includes(ingredient)
                                        )
                                    )
                                } else {
                                    updateFilterIngredients([...filteredIngredients, ...newFilteredIngredients])
                                }
                            } else {
                                if (
                                    filteredIngredients.includes(ingredientItem) ||
                                    filteredIngredients.includes(unlemmatizedIngredient)
                                ) {
                                    updateFilterIngredients(
                                        filteredIngredients.filter(
                                            (ingredient) =>
                                                ingredient !== ingredientItem && ingredient !== unlemmatizedIngredient
                                        )
                                    )
                                } else {
                                    updateFilterIngredients([...filteredIngredients, ingredientItem])
                                }
                            }
                        }}
                    />
                </ListItem>
            )
        }
    }, [filteredIngredients, updateFilterIngredients])

    const handleValueChange = useCallback((value, triggerQuery) => {
        setSearchObject({ value: value, triggerQuery: triggerQuery })
        triggerQuery()
    }, [])

    return (
        <>
            <Grid item xs={12}>
                <DataSearch
                    ref={dataSearchRef}
                    componentId="search"
                    type="search"
                    dataField={["name"]}
                    debounce={100}
                    queryFormat="and"
                    fuzziness={2}
                    placeholder="Search by recipe or ingredient"
                    showClear={true}
                    showIcon={true}
                    value={searchObject.value}
                    onChange={handleValueChange}
                    icon={<SearchIcon />}
                    innerClass={{
                        input: "search-input",
                    }}
                    className="search-field"
                    onFocus={() => setIsOpen(true)}
                    render={({
                        error,
                        data,
                        value,
                        downshiftProps: { getItemProps },
                    }) => {
                        if (error) {
                            return <div>Error: {error.message}</div>
                        }
                        if (isOpen && Boolean(value.length)) {
                            const foundIngredientSuggestions = ingredientSuggestions.filter(item => item.startsWith(value.toLowerCase()))
                            if (foundIngredientSuggestions.length === 0) {
                                return (
                                    <List dense={true}
                                        className="search-field search-list"
                                        sx={{ backgroundColor: "background.default" }}>
                                        <ListItem key="no-ingredients">
                                            <ListItemText primary="No ingredient suggestion found." />
                                        </ListItem>
                                    </List>
                                )
                            }
                            return (
                                <List dense={true}
                                    className="search-field search-list"
                                    sx={{ backgroundColor: "background.default" }}>
                                    {foundIngredientSuggestions.length > 0 && (
                                        <ListItem key="search-ingredients">
                                            <List sx={{ width: "100%" }}
                                                subheader={
                                                    <ListSubheader>
                                                        Ingredients
                                                    </ListSubheader>
                                                }>
                                                <ListItem key="search-ingredient-items">
                                                    <Masonry columns={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                                                        {foundIngredientSuggestions.slice(0, 5).map((item, index) => (
                                                            ingredientSuggestion(item, index)
                                                        ))}
                                                    </Masonry>
                                                </ListItem>
                                            </List>
                                        </ListItem>
                                    )}
                                </List>
                            )
                        }
                    }} />
            </Grid>
            <Grid container spacing={1} ml={0.5} mt={-0.5} mb={0.5} item xs={12}>
                <Grid mt={-1} item sx={{ display: "flex" }}>
                    {[...filteredIngredients].filter(filteredIngredient => ingredientSuggestions.includes(filteredIngredient))
                        .map((filteredIngredient, index) => (
                            <Grid item key={index}>
                                <Chip
                                    variant="filled"
                                    deleteIcon={<CloseIcon />}
                                    onDelete={() => closeFilter(filteredIngredient)}
                                    label={<Typography color="text.secondary">{filteredIngredient}</Typography>} />
                            </Grid>
                        ))}
                    {filteredIngredients.filter(filteredIngredient => ingredientSuggestions.includes(filteredIngredient)).length > 1 && (
                        <Grid item>
                            <Chip variant="contained" deleteIcon={<CloseIcon />}
                                onDelete={clearAllFilters} color="warning"
                                sx={{
                                    color: "text.secondary"
                                }}
                                label="clear all" />
                        </Grid>
                    )}
                </Grid>

            </Grid>
        </>
    )
}

export default SearchBar