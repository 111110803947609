import React, {
    Fragment
} from "react"

import { capitalize, standardize } from "../util"

function getKeyByValue(obj, value) {
    for (const [key, val] of Object.entries(obj)) {
        if (Array.isArray(val) && val.join(" ").includes(value)) {
            return key
        }
        if (val.includes(value) || value.includes(val)) {
            return key
        }
    }
    return null
}

const IngredientLine = ({ ingredient, taggedIngredient }) => (
    ingredient.split(" ").map((ingredientWord, index) => {
        let tagComponent = <span>{ingredientWord}</span>
        if (ingredientWord != "") {
            let tag = getKeyByValue(taggedIngredient, ingredientWord.toLowerCase())

            if (tag == null) {
                tag = getKeyByValue(taggedIngredient, standardize(ingredientWord.toLowerCase()))
            }

            if (tag === "QUANTITY") {
                tagComponent = <span style={{ fontSize: "1.25rem", fontFamily: "sans-serif" }}>
                    {ingredientWord.includes("(") && '('}<span style={{
                        fontWeight: "bolder"
                    }}>{ingredientWord.replaceAll("(", "").replaceAll(")", "")}</span>{ingredientWord.includes(")") && ')'}</span>
            } else if (tag === "STATE") {
                tagComponent = <span style={{ fontWeight: "lighter", fontFamily: "sans-serif" }}>{ingredientWord}</span>
            } else if (["DF", "TEMP"].includes(tag)) {
                tagComponent = <span style={{ fontFamily: "sans-serif" }}>{ingredientWord}</span>
            } else if (tag === "UNIT") {
                tagComponent = <span style={{
                    fontSize: "1.1rem",
                    fontFamily: "sans-serif"
                }}>
                    <i>{capitalize(ingredientWord.replaceAll(")", ""))}</i>{ingredientWord.includes(")") && ')'}
                </span>
            } else if (["NAME", "lemmatized_name"].includes(tag)) {
                tagComponent = <span style={{
                    fontSize: "1.1rem",
                    fontFamily: "sans-serif",
                    fontWeight: "600"
                }}>{capitalize(ingredientWord)}</span>
            }
        }

        return <Fragment key={index}>{tagComponent ? tagComponent : ingredientWord} </Fragment>
    })
)

export default IngredientLine