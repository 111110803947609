import {
  React,
} from 'react'
import { Link as RouterLink } from "react-router-dom"
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Rating,
  Divider,
} from '@mui/material'
import {
  Star as StarIcon
} from '@mui/icons-material'
import { cleanUpRecipeName } from '../util'
const nlpUtils = require('wink-nlp-utils')

const standardize = (word) => {
  word = nlpUtils.string.removePunctuations(word)
  word = word.replace(/ +/g, "_").trim()
  return word.toLowerCase()
}

export default function RecipeCard({
  recipe
}) {
  const title = cleanUpRecipeName(recipe.name)

  const rating = () => recipe.rating && (
    <div style={{ display: "flex" }}>
      <Rating icon={<StarIcon color="secondary" />} defaultValue={parseInt(recipe.rating)} precision={0.5} readOnly />
      <Typography>{recipe.ratingCount}</Typography>
    </div>
  )

  return (
    <Card variant="elevation" elevation={3}
      sx={{
        backgroundColor: 'background.paper',
        minWidth: "275px",
        maxWidth: "360px",
      }}>
      <CardActionArea
        component={RouterLink}
        to={`/recipes/${standardize(recipe.name)}/${recipe._id}`}>
        <CardMedia
          component="img"
          image={recipe.imageUrl}
          title={recipe.name}
          style={{
            filter: "brightness(100%) contrast(110%) hue-rotate(350deg)",
          }}
        />
        <CardHeader
          title={<Typography
            variant='h3'
            fontSize='1.5rem'
            fontWeight='600'
            fontFamily='sans-serif'
          >{title}</Typography>}
          subheader={<Typography color='text.secondary' variant='subtitle2'>{"Recipe by " + recipe.author}</Typography>}
          sx={{
            paddingBottom: 0
          }}>
        </CardHeader>
        <CardContent
          sx={{
            paddingBottom: 0
          }}>
          <Divider />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: "-0.5rem",
            paddingTop: 1,
            paddingBottom: 1
          }}>
            {rating()}
          </Box>
        </CardContent>

      </CardActionArea>
    </Card>
  )
}