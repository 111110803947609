import React, { Fragment } from 'react'

const PrivacyPolicy = () => (
    <Fragment>
        <h2>Privacy Policy</h2>
        <p>
            Our website displays ads. The advertisements may use cookies and web beacons to collect information about the user's visit to our website, including the user's IP address, browser type, and the pages visited. This information is used to serve ads that are relevant to the user's interests.
        </p>
        <p>
            Users can control the use of cookies and web beacons by adjusting their browser settings.
        </p>
        <p>
            If you have any questions or concerns about our use of ads or the data collected through it, please contact us at contact@internetscookbook.com.
        </p>
    </Fragment>
)

export default PrivacyPolicy
