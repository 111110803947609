const nlpUtils = require('wink-nlp-utils')

const cleanUpRecipeName = (input) => (
  input.replace(/ (recipe|by|\(|-).*/gi, "")
)

const standardize = (input) => {
  input = nlpUtils.string.removePunctuations(input)
  input = input.replace(/ +/g, " ").trim()
  return input
}

const capitalize = (str) => str.replace(/\b\w/g, c => c.toUpperCase())

export { cleanUpRecipeName, standardize, capitalize }