import { createTheme } from '@mui/material/styles'

//light version
const primary = '#4CAF50';
const secondary = '#F8C027';
const error = '#F54C4C';
const warning = '#F9A826';
const info = '#00B0FF';
const success = '#4CAF50';
const text_primary = 'rgba(0, 0, 0, 0.87)';
const text_secondary = 'rgba(0, 0, 0, 0.54)';
const text_disabled = 'rgba(0, 0, 0, 0.38)';
const divider = 'rgba(0, 0, 0, 0.12)';
const background_default = '#F5F5F5';
//const background_default = '#F5F5DC';
const background_paper = '#FFFFFF';

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
    warning: {
      main: warning,
    },
    info: {
      main: info,
    },
    success: {
      main: success,
    },
    text: {
      primary: text_primary,
      secondary: text_secondary,
      disabled: text_disabled,
    },
    divider: divider,
    background: {
      default: background_default,
      paper: background_paper,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: 'bold',
      fontSize: '2.5rem',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.75rem',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    subtitle1: {
      fontWeight: 'normal',
      fontSize: '1.5rem',
    },
    subtitle2: {
      fontWeight: 'normal',
      fontSize: '1rem',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '0.875rem',
    },
    button: {
      fontWeight: 'bold',
      fontSize: '0.875rem',
      textTransform: 'uppercase',
    }
  },
  shape: {
    borderRadius: 10,
  },
  props: {
    MuiButton: {
      size: 'large',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        background: 'linear-gradient(45deg, #4caf50 30%, #ff9800 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    },
    MuiButton: {
      root: {
        background: 'linear-gradient(45deg, #4caf50 30%, #ff9800 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      }, 
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  nesting: 2,
})

export default theme
